import React from 'react'
import i18next from "i18next";
export default class Retailer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <div >
                <ul className="scroller">
                    {this.props.data.map(function (retailer, index) {
                        return (
                            <li key={index} className="retailer-list" onClick={props.onClickLi.bind(null, index)}>
                                <h2> {i18next.languages[0] === "en" ? retailer.name_en : retailer.name_ar} </h2>
                                <p> {i18next.languages[0] === "en" ? retailer.description_en : retailer.description_ar}</p>
                                <p> {retailer.location.address} </p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}
