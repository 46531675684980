import React from "react";
import 'react-bootstrap';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import axios from "axios"
import i18next from "i18next";
class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/footer")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        return (
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto mt-3 text-md-left  mb-2 mb-md-0 order-first order-md-last">
                <h1>
                    {i18next.languages[0] === "en" ? data.join_newsletter_en : data.join_newsletter_ar}
                </h1>
                <p>
                    {i18next.languages[0] === "en" ? data.subscribe_text_en : data.subscribe_text_ar}
                </p>
                <div className="subscribe">
                    <MailchimpSubscribe url="https://gmail.us7.list-manage.com/subscribe/post?u=501938e4e3f01142d2996d155&amp;id=3548ec9220" />
                </div>
            </div>
        )
    }
}
export default Subscribe