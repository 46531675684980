import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Translation } from "react-i18next";
import i18next from "i18next";

export default class RetailerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { copied: false }
    }

    render() {
        const retailer = this.props.retailer;
        // const url = "https://admin.mtgl.com.sa";
        return (
            <Translation>
                {(t) => (
                    <div>
                        <div className="row">
                            <div className="col-12 col-md-12 mb-2 ">
                                <p>{t("retailLookup_s_retailer")}</p>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                    {retailer.image && <div className="image-cropper">
                                        <img src={retailer.image.url ? retailer.image.url : ""} className="profile-pic" alt={retailer.name_en} />

                                    </div>}
                                    </div>
                                    <div className="col-6 col-md-6 retailer-info">
                                        <h2>
                                            {i18next.languages[0] === "en" ? retailer.name_en : retailer.name_ar}
                                        </h2>
                                        <p> {i18next.languages[0] === "en" ? retailer.description_en : retailer.description_ar}
                                            <br />
                                            {retailer.location.address !== "Saudi Arabia" ? retailer.location.address : ""}</p>
                                        <p>
                                            <CopyToClipboard text={"https://www.google.com/maps/search/?api=1&query=" + retailer.location.lat + "," + retailer.location.lng}
                                                onCopy={() => this.setState({ copied: true })}>
                                                <span>
                                                    {retailer.location.address !== "Saudi Arabia" ? <FontAwesomeIcon icon={faShareSquare} size="1x" className=" text-dark" /> : ""}
                                                </span>
                                            </CopyToClipboard>
                                            {this.state.copied ? <span className="success-msg">{t("retailLookup_copied")}</span> : null}
                                        </p>

                                    </div>
                                </div>

                            </div>

                            <div className="col-md-4">
                                <div className="icon-div">
                                    {retailer.website ? <> <FontAwesomeIcon icon={faGlobe} className="icon text-dark" />
                                        <a href={"https://" + retailer.website} target="_blank" rel="noopener noreferrer">
                                            {retailer.website}
                                        </a>
                                        <br />
                                    </> : ""}
                                </div>
                                <div className="icon-div retailer-phone" >
                                    {retailer.phone ? <> <FontAwesomeIcon icon={faPhone} className="icon text-dark" />
                                        <a href={"tel:" + retailer.phone} dir="ltr">
                                            {retailer.phone}
                                        </a>
                                        <br />
                                    </> : ""}
                                </div>
                                <div className="icon-div">
                                    {retailer.email ? <> <FontAwesomeIcon icon={faEnvelope} className="icon text-dark" />
                                        <a href={"mailto:" + retailer.email}>
                                            {retailer.email}
                                        </a>
                                    </> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        )
    }
}
