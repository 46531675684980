import React from 'react';
import 'react-bootstrap';
import Helmet from "react-helmet";
import Members from './components/members';
import axios from "axios"
import i18next from "i18next";
import Social from './components/social';
import Loading from './components/Loading';
class TheLaboratory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/the-laboratory")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        if (data.length === 0) {
            return (
                <Loading />
            )
        }
        const url = "https://admin.mtgl.com.sa";
        return (
            <div className="container-fluid ">
                <Helmet>
                    <title>{i18next.languages[0] === "en" ? data.title_en : data.title_ar}</title>
                    <meta name="description"
                        content={i18next.languages[0] === "en" ? data.about_us_paragraph1_en : data.about_us_paragraph1_ar} />
                </Helmet>
                <div className="row">
                    <div className="laboratory-intro">

                        {/* about us */}
                        <div className="laboratory-intro-container">
                            <h1>
                                {i18next.languages[0] === "en" ? data.about_us_en : data.about_us_ar}
                            </h1>
                            <p>
                                {i18next.languages[0] === "en" ? data.about_us_paragraph1_en : data.about_us_paragraph1_ar}
                            </p>
                            <p>
                                {i18next.languages[0] === "en" ? data.about_us_paragraph2_en : data.about_us_paragraph2_ar}
                            </p>
                        </div> {/* close laboratory-intro-container */}
                    </div> {/* close laboratory-intro*/}
                </div>
                <div className="laboratory-second-part">
                    <div className="container ">
                        {/* social */}
                        <div className="row ">
                            <div className="col-12 mb-3 " >

                                <div className="fSocial">
                                    <ul className="">
                                        <Social />
                                    </ul>
                                </div> {/* close social */}

                            </div> {/* close col */}
                        </div> {/* close row */}
                        <div className="row mb-5">

                            {/* brand story */}

                            <div className="col-md-10 brand-story">
                                <h1>
                                    {i18next.languages[0] === "en" ? data.brand_story_en : data.brand_story_ar}
                                </h1>
                                <p>
                                    {i18next.languages[0] === "en" ? data.brand_story_paragraph1_en : data.brand_story_paragraph1_ar}
                                </p>
                                <p>
                                    {i18next.languages[0] === "en" ? data.brand_story_paragraph2_en : data.brand_story_paragraph2_ar}
                                </p>
                            </div> {/* close col */}
                        </div> {/* end of row */}

                        <div className="row mt-5 mb-5">
                            <div className="col-12 mb-3">
                                <h1>
                                    {i18next.languages[0] === "en" ? data.symbols_of_mblem_en : data.symbols_of_mblem_ar}
                                </h1>
                            </div>
                            <div className="card col-12 col-md-6" >
                                <div className="row" >
                                    <div className="card-header col-2 col-md-2 card-side" >
                                    </div>
                                    <div className="card-body col-10 col-md-10">
                                        <table className="table table-sm mb-0 table-collapse">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <img src={url + data.symbols_of_mblem_item1_logo[0].url} className="card-icon" alt={data.symbols_of_mblem_item1_logo[0].name} />
                                                    </th>
                                                    <td> {i18next.languages[0] === "en" ? data.symbols_of_mblem_item1_en : data.symbols_of_mblem_item1_ar} </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="col">
                                                        <img src={url + data.symbols_of_mblem_item2_logo[0].url} className="card-icon" alt={data.symbols_of_mblem_item2_logo[0].name} />
                                                    </th>
                                                    <td>{i18next.languages[0] === "en" ? data.symbols_of_mblem_item2_en : data.symbols_of_mblem_item2_ar}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">
                                                        <img src={url + data.symbols_of_mblem_item3_logo[0].url} className="card-icon" alt={data.symbols_of_mblem_item3_logo[0].name} />
                                                    </th>
                                                    <td>{i18next.languages[0] === "en" ? data.symbols_of_mblem_item3_en : data.symbols_of_mblem_item3_ar}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">
                                                        <img src={url + data.symbols_of_mblem_item4_logo[0].url} className="card-icon" alt={data.symbols_of_mblem_item4_logo[0].name} />
                                                    </th>
                                                    <td>{i18next.languages[0] === "en" ? data.symbols_of_mblem_item4_en : data.symbols_of_mblem_item4_ar}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">
                                                        <img src={url + data.symbols_of_mblem_item5_logo[0].url} className="card-icon" alt={data.symbols_of_mblem_item5_logo[0].name} />
                                                    </th>
                                                    <td>{i18next.languages[0] === "en" ? data.symbols_of_mblem_item5_en : data.symbols_of_mblem_item5_ar}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> {/* close card body */}
                                </div> {/* close row */}
                            </div> {/* end of card */}
                            <div className="col-md-6  col-12 lab-logo ">
                                <img className="img-fluid p-5" src={url + data.mtgl_logo[0].url} alt={data.mtgl_logo[0].name} />
                            </div>
                        </div>{/* close row */}
                        <div className="row ">
                            <div className="col-12 col-md-12 col-lg-12 mb-3">
                                <h1>
                                    {i18next.languages[0] === "en" ? data.what_we_value_en : data.what_we_value_ar}
                                </h1>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="laboratory-yellow">
                                        <h3>
                                            {i18next.languages[0] === "en" ? data.what_we_value_header1_en : data.what_we_value_header1_ar}
                                        </h3>
                                        <p>
                                            {i18next.languages[0] === "en" ? data.what_we_value_paragraph1_en : data.what_we_value_paragraph1_ar}
                                        </p>
                                        <h3>
                                            {i18next.languages[0] === "en" ? data.what_we_value_header2_en : data.what_we_value_header2_ar}
                                        </h3>
                                        <p>
                                            {i18next.languages[0] === "en" ? data.what_we_value_paragraph2_en : data.what_we_value_paragraph2_ar}
                                        </p>
                                        <h3>
                                            {i18next.languages[0] === "en" ? data.what_we_value_header3_en : data.what_we_value_header3_ar}
                                        </h3>
                                        <p>
                                            {i18next.languages[0] === "en" ? data.what_we_value_paragraph3_en : data.what_we_value_paragraph3_ar}
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="gemicon lab-gemicon mt-5">
                                                <img src="https://i.ibb.co/7Wrcdhp/Diamonds-Gold.png" className="img-fluid  d-block d-md-none mx-auto" alt="Diamonds-Gold" />
                                            </div>
                                        </div> </div>
                                </div>


                                <div className="col-12 col-md-6 col-lg-6 value">
                                    <div className="value-p ">
                                        <p>
                                            {i18next.languages[0] === "en" ? data.what_we_value_main_paragraph1_en : data.what_we_value_main_paragraph1_ar}
                                        </p>
                                        <p>
                                            {i18next.languages[0] === "en" ? data.what_we_value_main_paragraph2_en : data.what_we_value_main_paragraph2_ar}
                                        </p>
                                    </div>
                                </div>
                            </div> {/* close row */}
                            {/* </div> */}
                        </div>
                    </div>
                </div> {/* laboratory second part */}

                <div className="row">
                    <div className="laboratory-third-part">
                        <img src={url + data.accreditation_image[0].url} alt={data.accreditation_image[0].name} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="laboratory-fourth-part">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 col-12">
                                        <h1>
                                            {i18next.languages[0] === "en" ? data.accreditation_en : data.accreditation_ar}
                                        </h1>
                                        <p>
                                            {i18next.languages[0] === "en" ? data.accreditation_paragraph_en : data.accreditation_paragraph_ar}
                                        </p>
                                        <img width='150px' src='https://i.ibb.co/XW31m8b/Diamonds-Green.png' className="green-diamond" alt="Diamonds-Green" />
                                    </div>
                                    <div className="col-md-4 col-12 Accreditation">
                                        <img width='200px' src={url + data.accreditation_logo[0].url} alt={data.accreditation_logo[0].name} />
                                    </div>
                                </div> {/* close row */}

                            </div> {/* close container */}
                        </div> {/* close fourth part*/}
                    </div>
                </div>
                <div className="laboratory-fifth-part ">

                    <div className="row ">

                        <div className="col-12 col-md-4 lab-sci">
                            <img src={url + data.our_laboratory_mission_image[0].url} alt={data.our_laboratory_mission_image[0].name} />
                        </div>

                        <div className="col-12 col-md-8  laboratory-mission-container">
                            <h1>
                                {i18next.languages[0] === "en" ? data.our_laboratory_mission_en : data.our_laboratory_mission_ar}
                            </h1>
                            <p>
                                {i18next.languages[0] === "en" ? data.our_laboratory_mission_paragraph1_en : data.our_laboratory_mission_paragraph1_ar}
                            </p>
                            <p>
                                {i18next.languages[0] === "en" ? data.our_laboratory_mission_paragraph2_en : data.our_laboratory_mission_paragraph2_ar}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="laboratory-fifth-part Affiliations " >
                    <div className="row ">
                        <div className="col-md-4"></div>
                        <div className="mt-5 col-12 col-md-8 " >
                            <h1 className="text-dark">
                                {i18next.languages[0] === "en" ? data.affiliations_memberships_en : data.affiliations_memberships_ar}
                            </h1>
                            <p>
                                {i18next.languages[0] === "en" ? data.affiliations_memberships_paragraph_en : data.affiliations_memberships_paragraph_ar}
                            </p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row my-5" >
                            <div className="col-12 col-md-12 text-center">
                                <Members />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

export default TheLaboratory;
