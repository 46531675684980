import React from 'react'
import './service.css'
import Helmet from "react-helmet";
import axios from "axios"
import i18next from "i18next";
import Loading from './components/Loading';
class Service extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/service")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        if (data.length === 0) {
            return (
                <Loading />
            )
        }
        return (
            <div className="container-fluid service">
                <Helmet>
                    <title>{i18next.languages[0] === "en" ? data.title_en : data.title_ar}</title>
                    <meta name="description"
                        content={i18next.languages[0] === "en" ? data.paragraph_en : data.paragraph_ar} />
                </Helmet>
                <div className="row">
                    <div className="col-12 p-0 ">
                        <div className="service-container ">
                            <div className="services serv-head">
                                <div className="container ">
                                    <div className="row">
                                        <div className="col-12 col-md-8 mx-auto">
                                            <h1>
                                                {i18next.languages[0] === "en" ? data.title_en : data.title_ar}
                                            </h1>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-8 mx-auto">
                                            <p>
                                                {i18next.languages[0] === "en" ? data.paragraph_en : data.paragraph_ar}
                                            </p>
                                        </div> </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-0">
                        <div className=" service-body">
                            <div className="container-fluid ">

                                <ul>

                                    <div className="row">

                                        <a href="/Service/DiamondReport" className="col-12 col-md-4 h-head shadow-sm">

                                            <li className="nav-item">
                                                {i18next.languages[0] === "en" ? data.service_list1_en : data.service_list1_ar}
                                            </li>

                                        </a>
                                        <a href="/Service/GemstoneReport" className="col-12 col-md-4  h-head shadow-sm">
                                            <li className="nav-item">
                                                {i18next.languages[0] === "en" ? data.service_list2_en : data.service_list2_ar}
                                            </li>
                                        </a>
                                        <a href="/Service/JewelryReport" className="col-12 col-md-4  h-head shadow-sm">
                                            <li className="nav-item">
                                                {i18next.languages[0] === "en" ? data.service_list3_en : data.service_list3_ar}
                                            </li>
                                        </a>
                                    </div>
                                    <div className="row">

                                        <a href="/Service/DiamondSealing" className="col-12 col-md-4  h-head shadow-sm">
                                            <li className="nav-item">
                                                {i18next.languages[0] === "en" ? data.service_list4_en : data.service_list4_ar}
                                            </li>
                                        </a>
                                        <a href="/Service/ScreeningService" className="col-12 col-md-4  h-head shadow-sm">
                                            <li className="nav-item">
                                                {i18next.languages[0] === "en" ? data.service_list5_en : data.service_list5_ar}
                                            </li>
                                        </a>
                                        <a href="/Service/OnSiteTesting" className="col-12 col-md-4  h-head shadow-sm">
                                            <li className="nav-item">
                                                {i18next.languages[0] === "en" ? data.service_list6_en : data.service_list6_ar}
                                            </li>
                                        </a>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="features-services">
                            <div className="container">
                                <h4>
                                    {i18next.languages[0] === "en" ? data.security_features_en : data.security_features_ar}
                                </h4>
                                <p>
                                    {i18next.languages[0] === "en" ? data.security_features_paragraph1_en : data.security_features_paragraph1_ar}
                                </p>
                                <p>
                                    {i18next.languages[0] === "en" ? data.security_features_paragraph2_en : data.security_features_paragraph2_ar}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Service;
