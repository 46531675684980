import React from 'react'
import { Translation } from "react-i18next";
import '../service.css';
import i18next from "i18next";
import { NavLink } from 'react-router-dom';
export default class jewelryReportBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            links: []
        };
    }
    componentDidMount() {
        window.scrollTo(80, 0)
        this.fetcData();
        this.fetcLinks();
    }
    fetcLinks() {
        fetch(`https://admin.mtgl.com.sa/service`)
            .then(response => response.json())
            .then(links =>
                this.setState({ links }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    fetcData() {
        fetch(`https://admin.mtgl.com.sa/jewelry-report`)
            .then(response => response.json())
            .then(data =>
                this.setState({ data }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    render() {
        const data = this.state.data
        const links = this.state.links
        return (
            <Translation>
                {(t) => (
                    <div>
                        <div className="diamond-body service-body" >
                            <div className="container p-md-5">
                                <div className="row">
                                    <div className="col-md-12 d-none d-md-flex justify-content-end">
                                        <div className="arrow-head">
                                            <span> &#9660;</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 ">

                                        <h1>
                                            {i18next.languages[0] === "en" ? links.service_list3_en : links.service_list3_ar}
                                        </h1>
                                        <p>
                                            {i18next.languages[0] === "en" ? data.paragraph1_en : data.paragraph1_ar}
                                        </p>
                                        <p>
                                            {i18next.languages[0] === "en" ? data.paragraph2_en : data.paragraph2_ar}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Gemstone-body row">
                            <NavLink to="/Service/JewelryReportMiddle/StandardJewelryReport" className=" col-md-6 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                {i18next.languages[0] === "en" ? data.jewelry_standard_en : data.jewelry_standard_ar}
                            </NavLink>
                            <NavLink to="/Service/JewelryReportMiddle/LargeJewelryReport" className=" col-md-6 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                {i18next.languages[0] === "en" ? data.jewelry_large_en : data.jewelry_large_ar}
                            </NavLink>
                            <NavLink to="/Service/JewelryReportMiddle/PremiumJewelryReport" className=" col-md-6 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                {i18next.languages[0] === "en" ? data.jewelry_premium_en : data.jewelry_premium_ar}
                            </NavLink>
                            <NavLink to="/Service/JewelryReportMiddle/CardJewelryReport" className=" col-md-6 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                {i18next.languages[0] === "en" ? data.jewelry_card_en : data.jewelry_card_ar}
                            </NavLink>
                        </div>

                    </div>
                )}
            </Translation>
        )
    }
}
