import React from 'react';
import MapSection from './components/map/Map';
import $ from 'jquery';
import update from 'immutability-helper';
import Branch from './components/branch';
import ContactUsForm from './components/contactUsForm';
import BranchInfo from './components/branchInfo';
import Helmet from "react-helmet";
import { Translation } from "react-i18next";
import Loading from './components/Loading';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      location: {
        address: {
          en: '2533 Al Tahlia Commercial Center, Prince Mohammed Bin Abdulaziz Road, Al Rawdah District, 23431 Jeddah, Saudi Arabia',
          ar: '٢٥٣٣ مركز التحلية التجاري، طريق الأمير محمد بن عبدالعزيز، حي الروضة، ٢٣٤٣١ جدة، المملكة العربية السعودية'
        },
        lat: 24.740366475130507,
        lng: 46.66142118982265 
      },
      branch: {
        name: {
          en: 'Jeddah',
          ar: 'جدة'
        
        },
        fax: '',
        phone: '',
        email: ''
      }
    };
  }

  render() {
    const { branch } = this.state;
    if (branch.length === 0) {
      return (
          <Loading />
      )
  }
    return (
      <Translation>
        {(t) => (
          <div className="container-fluid ">
            <Helmet>
              <title>{t("header_contact")}</title>
              <meta name="description" content="Helmet application" />
            </Helmet>
            <div className="container retail-lookup mt-5">
              <div className="row mt-5">
                <div className="col-12 col-md-6">
                  <h1>{t("contact_h1")}</h1>
                  <p>{t("contact_p1")} <br />
                    {t("contact_p2")}</p>
                </div>
                <div className="col-6 col-md-6">
                  <p className="text-right contact_p3">  {t("contact_p3")}</p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6">
                  <ContactUsForm branchName={branch.name} />
                </div> {/* col */}
                <div className="col-md-4">
                  <Branch locationPicker={this.locationPicker} />
                </div> {/* col */}
              </div> {/* row */}
            </div> {/* container */}
            <div className="row">
              <div className="col-12 p-0">
                <MapSection location={this.state.location} zoomLevel={20} />
              </div>
            </div>
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-6">
                  <BranchInfo branch={this.state.branch} location={this.state.location} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    )
  }

  locationPicker = (e) => {
    /* which radio btn did the user choose */
    $("#contact-us-btn").removeAttr('disabled');

    if (e.target.value === 'j') {
      /* Jeddah */

      var location = update(this.state.location, {
        address: { $set: {
          en: '2533 Al Tahlia Commercial Center, Prince Mohammed Bin Abdulaziz Road, Al Rawdah District, 23431 Jeddah, Saudi Arabia',
          ar: '٢٥٣٣ مركز التحلية التجاري، طريق الأمير محمد بن عبدالعزيز، حي الروضة، ٢٣٤٣١ جدة، المملكة العربية السعودية'
        }
        },
        lat: { $set: 21.54983285931104 },
        lng: { $set: 39.148391238383624 }
      });
      var branch = update(this.state.branch, {
        name: { $set: {
          en: 'Jeddah',
          ar: 'جدة'
        }
       },
        fax: { $set: '' },
        phone: { $set: '+966555200683' },
        email: { $set: 'info@mtgl.com.sa' }
      });
      this.setState({ location: location });
      this.setState({ branch: branch });

    } else if (e.target.value === 'r') {
      /* Riyadh */

      location = update(this.state.location, {
        address: { $set: {
          en: '7358 Dar Al Wokalat Center, Al Olaya Street, King Fahad District, 12262 Riyadh, Saudi Arabia',
          ar: '٧٣٥٨ مركز دار الوكالات، شارع العليا، حي الملك فهد، ١٢٢٦٢ الرياض، المملكة العربية السعودية'
        }
      },
        lat: { $set: 24.740366475130507},
        lng: { $set: 46.66142118982265 }
      });
      branch = update(this.state.branch, {
        name: { $set: {
          en: 'Riyadh',
          ar: 'الرياض'
        }
      },
        fax: { $set: '' },
        phone: { $set: '+966555200683' },
        email: { $set: 'info@mtgl.com.sa' }

      });
      this.setState({ location: location });
      this.setState({ branch: branch });
    }

  }


}


export default Contact;
