import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Translation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import Helmet from "react-helmet";
import update from "immutability-helper";
import Loading from "./components/Loading";
// import Carsouel from './components/carsouel';

const recaptchaRef = React.createRef();
const API_key = process.env.REACT_APP_RECAPCHA;

class ReportVerfiy extends React.Component {
  constructor(props) {
    super(props);
    this.onInputchange = this.onInputchange.bind(this);
    /*  this.submitForm = this.submitForm.bind(this); */
    this.state = {
      status: "",
      isVisible: false,
      code: "",
      reCaptcha: {
        verified: false,
        reCaptchaResponse: "",
      },
      reportID: "",
      abortController: null,  
    };
  }

  onSubmit = (ev) => {
    ev.preventDefault();
    if ($(".text-field").val()) {
      if (this.state.reCaptcha.verified) {
        if (this.state.reCaptcha.reCaptchaResponse.valueOf()) {
          // Abort any ongoing request
          if (this.state.abortController) {
            this.state.abortController.abort();
          }

          // Create a new AbortController instance
          const controller = new AbortController();
          const { signal } = controller;

          // Update state with new controller
          this.setState({ abortController: controller });

          fetch(`https://webapp.mtgl.com.sa/api/process/report/display/${this.state.code}`, { mode: 'cors', signal })
            .then((response) => response.json())
            .then((report) => {
              const { message } = report;
              this.setState({ report });
              this.setState({ isVisible: true });
              if (message) {
                return;
              }
              this.setState({ report });
            })
            .catch((error) => {
              if (error.name === 'AbortError') {
                console.log('Request canceled');
              } else {
                this.setState({ error, isLoading: false });
              }
            });
        } else {
          /* error in recaptcha */
          this.setState({ status: "ERROR" });
        }
      } else {
        /* recaptcha is not checked */
        this.setState({ status: "RECAPTCHA" });
      }
    } else {
      /* field is empty */
      this.setState({ status: "EMPTY" });
    }
  };
  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onChange = (result) => {
    /* when user passes test */
    console.log("submit ", result);
    var reCaptcha = update(this.state.reCaptcha, {
      verified: { $set: true },
      reCaptchaResponse: { $set: result },
    });
    this.setState({ reCaptcha });
  };

  componentDidMount() {
    this.fetchData();
    this.fillVerifyReportForm();
  }
  fetchData() {
    fetch(`https://admin.mtgl.com.sa/verify-your-report`)
      .then((response) => response.json())
      .then((data) => this.setState({ data }))
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  fillVerifyReportForm() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    if (code) {
      this.setState({ code });
    }
  }

  render() {
    const { status } = this.state;
    const data  = this.state;
    if (data.length === 0) {
      return (
          <Loading />
      )
  }
    return (
      <Translation>
        {(t) => (
          /* verify-report-main */
          <div className="container-fluid">
            <Helmet>
              <title>{t("header_reportVerfiy")}</title>
              <meta name="description" content="Helmet application" />
            </Helmet>
            <div className="container retail-lookup mt-5">
              <div className="row mt-5">
                <div className="col-12 col-md-12">
                  <h1>{t("reportverify_title")}</h1>
                </div>
              </div>
              <div className="row mt-3" style={{ minHeight: "375px" }}>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <p>{t("reportverify_p")}</p>
                    </div>
                    <div className="col-12 mt-3">
                      <form onSubmit={this.onSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-group search-field">
                              <input
                                type="text"
                                name="code"
                                onChange={this.onInputchange}
                                value={this.state.code}
                                className="form-control text-field"
                                placeholder={t("reportverify_code-placeholder")}
                              />
                              <FontAwesomeIcon
                                icon={faSearch}
                                style={{ color: "gray" }}
                                className="mt-2 mr-3"
                              />
                              <button
                              style={{borderRadius: "0px 4px 4px 0px"}}
                                type="submit"
                                className="col-3 navbar-btn btn"
                              >
                                {" "}
                                {t("contact_form_submit")}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 my-5 mx-0 overflow-hidden w-100 recaptcha">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LekjPoZAAAAANMzO0Dq7yyHQDYKx8PYEfSi5r-o"
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="mb-5">
                          {status === "ERROR" && (
                            <span className="error-msg">
                              {t("contact_form_error")}
                            </span>
                          )}
                          {status === "EMPTY" && (
                            <span className="error-msg">
                              {t("contact_form_empty")}
                            </span>
                          )}
                          {status === "RECAPTCHA" && (
                            <span className="error-msg">
                              {t("reportverify_recapcha")}
                            </span>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div
                    className="col-12"
                    style={{
                      display: !this.state.isVisible ? "none" : "inline-block",
                    }}
                  >
                    <div className="row">
                      {this.state.report && this.state.report.message && (
                        <>
                          <div
                            className="alert alert-danger col-12"
                            role="alert"
                          >
                            {this.state.report.message}
                          </div>
                          <p className="mb-2 col-12" id="wait-p">
                            {t("wait-p")}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="row">
                      {this.state.report && this.state.report.url && (
                        <object
                          data={`${this.state.report.url}#toolbar=0`}
                          width="100%"
                          height="300px"
                          type="application/pdf"
                        >
                          <embed
                            src={`${this.state.report.url}#toolbar=0`}
                            type="application/pdf"
                          />
                        </object>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default ReportVerfiy;
