import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
const Header = ({dark}) => {
    const { i18n } = useTranslation();
    const [logo, setLogo] = useState("https://i.ibb.co/hgykR11/Metal-Testing-Horizontal-Small-Logo.png");
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    const [navbar, setNavbar] = useState(false)
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
            setLogo("https://i.ibb.co/VMCdk01/logo.png")
        } else {
            setNavbar(false)
            setLogo("https://i.ibb.co/hgykR11/Metal-Testing-Horizontal-Small-Logo.png")
        }
    }
    window.addEventListener("scroll", changeBackground)

    const [data, setData] = useState([]);
    async function fetchData() {
        const res = await fetch("https://admin.mtgl.com.sa/header");
        res
            .json()
            .then(res => setData(res))
    }

    useEffect(() => {
        fetchData();
        var darkPages = ['/RetailLookup', '/contact', '/ReportVerify', '/Terms']
        if (darkPages.includes(window.location.pathname)) {
            setNavbar(true)
        } else {
            window.addEventListener("scroll", changeBackground);
        }
    }, []);

    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expand="lg" fixed="top" className={`${dark ? "active ": ""} ${navbar ? "navbar active " : "navbar"}`}
         expanded={expanded} variant="dark">
            <div className="container p-2">
                <Navbar.Brand className="navbrand" href="/">
                    <img src={logo} className="d-inline-block align-top" width="152" alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarSupportedContent" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="navbarSupportedContent" >

                    <Nav className="navbar-ul text-uppercase">
                        <NavLink exact to="/" style={{ color: 'white' }} className="nav-link home" activeClassName="nav-link--active" onClick={() => setExpanded(false)}>
                            {i18next.languages[0] === "en" ? data.home_en : data.home_ar}
                        </NavLink>
                        <NavLink to="/TheLaboratory" style={{ color: 'white' }} className="nav-link" activeClassName="nav-link--active" onClick={() => setExpanded(false)}>
                            {i18next.languages[0] === "en" ? data.the_laboratory_en : data.the_laboratory_ar}
                        </NavLink>
                        <NavLink to="/Service" style={{ color: 'white' }} className="nav-link" activeClassName="nav-link--active" onClick={() => setExpanded(false)} >
                            {i18next.languages[0] === "en" ? data.service_en : data.service_ar}
                        </NavLink>
                        <NavLink to="/RetailLookup" style={{ color: 'white' }} className="nav-link" activeClassName="nav-link--active" onClick={() => setExpanded(false)}>
                            {i18next.languages[0] === "en" ? data.retailer_lookup_en : data.retailer_lookup_ar}
                        </NavLink>
                        <NavLink to="/contact" style={{ color: 'white' }} className="nav-link" activeClassName="nav-link--active" onClick={() => setExpanded(false)}>
                            {i18next.languages[0] === "en" ? data.contact_en : data.contact_ar}
                        </NavLink>
                        <NavLink to="/ReportVerify" style={{ color: 'white' }} className="nav-link" activeClassName="nav-link--active" onClick={() => setExpanded(false)}>
                            {i18next.languages[0] === "en" ? data.report_verify_en : data.report_verify_ar}
                        </NavLink>
                        <li style={{ color: 'white' }} className="nav-link" onClick={() => setExpanded(false)}>
                            {i18n.language === 'en' ?
                                <button className="lang-btn ar" onClick={() => changeLanguage('ar')}>العربية</button>
                                :
                                <button className="lang-btn en" onClick={() => changeLanguage('en')}>English</button>
                            }
                        </li>

                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    )


}

export default Header;
