import React from "react"
import { Translation } from "react-i18next";
import Helmet from "react-helmet";

export default class Terms extends React.Component {
    render() {
        return (
            <Translation>
                {(t) => (
                    <div className="container-fluid ">
                        <Helmet>
                            <title>{t("terms_header")}</title>
                            <meta name="description" content="Helmet application" />
                        </Helmet>
                        <div className="container terms mt-5">
                            <div className="row mt-5">
                                <div className="col-12 col-md-12 ">
                                    <h1 className="terms-header">{t("terms_header")}</h1>
                                </div>
                                <div className="col-12 TERMS">

                                    <p>{t("terms_p1")}</p>
                                    <h3>{t("terms_validity")}</h3>
                                    <p>{t("validity_p1")}</p>
                                    <p>{t("validity_p2")}</p>
                                    <h3>{t("expressions_header")}</h3>
                                    <p>{t("expressions_p1")}</p>
                                    <p>{t("expressions_p2")}</p>
                                    <p>{t("expressions_p3")}</p>
                                    <p>{t("expressions_p4")}</p>
                                    <p>{t("expressions_p5")}</p>
                                    <p>{t("expressions_p6")}</p>
                                    <p>{t("expressions_p7")}</p>
                                    <p>{t("expressions_p8")}</p>
                                    <p>{t("expressions_p9")}</p>
                                    <p>{t("expressions_p10")}</p>
                                    <h3>{t("terms_service")}</h3>
                                    <p>{t("terms_service_p1")}</p>
                                    <p>{t("terms_service_p2")}</p>
                                    <p>{t("terms_service_p3")}</p>
                                    <p>{t("terms_service_p4")}</p>
                                    <p>{t("terms_service_p5")}</p>
                                    <p>{t("terms_service_p6")}</p>
                                    <h3>{t("service_fee")}</h3>
                                    <p>{t("service_fee_p")}</p>
                                    <h3>{t("legitimacy")}</h3>
                                    <p>{t("legitimacy_p1")}</p>
                                    <p>{t("legitimacy_p2")}</p>
                                    <h3>{t("laboratory_report")}</h3>
                                    <p>{t("laboratory_report_p1")}</p>
                                    <p>{t("laboratory_report_p2")}</p>
                                    <p>{t("laboratory_report_p3")}</p>
                                    <p>{t("laboratory_report_p4")}</p>
                                    <p>{t("laboratory_report_p5")}</p>
                                    <p>{t("laboratory_report_p6")}</p>
                                    <p>{t("laboratory_report_p7")}</p>
                                    <p>{t("laboratory_report_p8")}</p>
                                    <p>{t("laboratory_report_p9")}</p>
                                    <h3>{t("validity_report")}</h3>
                                    <p>{t("validity_report_p1")}</p>
                                    <p>{t("validity_report_p2")}</p>
                                    <ul>
                                        <li>
                                            - {t("validity_report_li1")}
                                        </li><br />
                                        <li>
                                            - {t("validity_report_li2")}
                                        </li><br />
                                        <li>
                                            - {t("validity_report_li3")}
                                        </li><br />
                                        <li>
                                            - {t("validity_report_li4")}
                                        </li><br />
                                        <li>
                                            - {t("validity_report_li5")}
                                        </li><br />
                                        <li>
                                            - {t("validity_report_li6")}
                                        </li><br />
                                    </ul>
                                    <h3>{t("complaints_suggestions")}</h3>
                                    <p>{t("complaints_suggestions_p")}</p>
                                    <h3>{t("condition_piece")}</h3>
                                    <p>{t("condition_piece_p")}</p>
                                    <h3>{t("delivery_storage")}</h3>
                                    <p>{t("delivery_storage_p")}</p>
                                    <h3>{t("abandoned")}</h3>
                                    <p>{t("abandoned_p")}</p>
                                    <h3>{t("disclaimer")}</h3>
                                    <p>{t("disclaimer_p1")}</p>
                                    <p>{t("disclaimer_p2")}</p>
                                    <p>{t("disclaimer_p3")}</p>
                                    <p>{t("disclaimer_p4")}</p>

                                    <h3>{t("indemnification")}</h3>
                                    <p>{t("indemnification_p")}</p>
                                    <h3>{t("insurance")}</h3>
                                    <p>{t("insurance_p1")}</p>
                                    <p>{t("insurance_p2")}</p>
                                    <p>{t("insurance_p3")}</p>
                                    <h3>{t("no_waiver")}</h3>
                                    <p>{t("no_waiver_p")}</p>
                                    <h3>{t("severability")}</h3>
                                    <p>{t("severability_p")}</p>
                                    <h3>{t("compliane_law")}</h3>
                                    <p>{t("compliane_law_p")}</p>
                                    <h3>{t("force_majeure")}</h3>
                                    <p>{t("force_majeure_p")}</p>

                                    <h3>{t("confidentiality")}</h3>
                                    <p>{t("confidentiality_p")}</p>
                                    <h3>{t("privacy_notice")}</h3>
                                    <p>{t("privacy_notice_p")}</p>
                                    <h3>{t("content")}</h3>
                                    <p>{t("content_p")}</p>
                                    <h3>{t("dispute_resolution")}</h3>
                                    <p>{t("dispute_resolution_p")}</p>
                                </div>
                            </div>
                        </div>




                    </div>
                )}
            </Translation>
        )
    }
}