import React from 'react';

function Loading() {
  // Define styles
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#212d27'
  };

  const logoStyle = {
    width: '200px'  // Adjust the width as needed
  };

  return (
    <div style={containerStyle}>
      <img src="https://i.ibb.co/VMCdk01/logo.png" alt="Logo" style={logoStyle}/>
    </div>
  );
}

export default Loading;
