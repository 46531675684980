import React from 'react';
import ServicesList from './components/servicesList';
import JewelryBody from './components/jewelryReportBody';
import Helmet from "react-helmet";
import axios from "axios"
import i18next from "i18next";
class JewelryReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/jewelry-report")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        return (
            <div className="container-fluid jewelryReport">
                <Helmet>
                    <title>
                        {i18next.languages[0] === "en" ? data.title_en : data.title_ar}
                    </title>
                    <meta name="description"
                        content={i18next.languages[0] === "en" ? data.paragraph1_en : data.paragraph1_ar} />
                </Helmet>
                <ServicesList />
                <div className="row mt-md-5">
                    <div className="col-12 p-0 ">
                        <JewelryBody />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-0 ">
                        <div className=" GemstoneReport service-body">
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default JewelryReport;
