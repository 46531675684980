import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios"
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default class members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    axios.get("https://admin.mtgl.com.sa/members")
      .then(({ data }) => this.setState({ data }));
  }
  render() {
    const data = this.state.data;
    const url = "https://admin.mtgl.com.sa";
    return (
      <div>

        <Carousel
          arrows={false}
          swipeable={false}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          customTransition="all 800ms linear"
          transitionDuration={500}
          containerClass="carousel-container-members"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {data.map(function (member) {
            return (
              <div key={member.id} className="d-flex justify-content-center">
                <div className="members-img-container ">
                  <a href={member.link} target="_blank" rel="noopener noreferrer">
                    <img src={url + member.logo[0].url} className="img-fluid" alt={member.logo[0].name} />
                  </a>
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    )
  }
}
