import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFax } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

export default class branchInfo extends React.Component {
    
    render() {
        const currentLanguage = i18next.languages[0];
        const branch = this.props.branch;
        const location = this.props.location;

        return (
            <div style={{
                direction: currentLanguage === "ar" ? "rtl" : "ltr",
                textAlign: currentLanguage === "ar" ? "right" : "left"
            }}>

                <h2>{branch.name[currentLanguage]}</h2>
                {location.address !== "Saudi Arabia" ? <p>{location.address[currentLanguage]}</p> : ""}
                <div>
                    <div className="icon-div">
                        {branch.fax ? <> <FontAwesomeIcon icon={faFax} className="icon text-dark" size="1x" /> <a href={"fax:" + branch.fax}>{branch.fax}</a> <br /> </> : ""}
                    </div>

                    <div className="icon-div">
                        {branch.phone ? <> <FontAwesomeIcon icon={faPhone} className="icon text-dark" size="1x" /> <a href={"tel:" + branch.phone}>{branch.phone}</a> <br />  </> : ""}
                    </div>
                    <div className="icon-div">
                        {branch.email ? <> <FontAwesomeIcon icon={faEnvelope} className="icon text-dark" size="1x" /> <a href={"mailto:" + branch.email}>{branch.email}</a> </> : ""}
                    </div>
                </div>
            </div>
        )
    }
}
