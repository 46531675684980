import React from 'react'
import $ from 'jquery';

import { Translation } from "react-i18next";

export default class ContactUsForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = { status: '' }
    }

    render() {
        const props = this.props;
        const { status } = this.state;

        return (
            <Translation>
                {(t) => (
                    <div>
                        <div className="card">

                            <div className="card-body">
                                <form id="contact-us" onSubmit={this.submitForm} action="https://formspree.io/f/mnqopelb" method="post"
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control text-field" placeholder={t("contact_form_first_name")} name="First-Name" id="First-Name" />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control text-field" placeholder={t("contact_form_second_name")} name="Second-Name" id="Second-Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="email" className="form-control text-field" placeholder={t("contact_form_email")} name="email" id="email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="tel" className="form-control text-field" placeholder={t("contact_form_mobile_number")} name="Mobile-Number" id="Mobile-Number" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea type="text" className="form-control text-field" placeholder={t("contact_form_message")} name="Message" id="Message" />
                                                <input type="hidden" name="branch" value={props.branchName} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">

                                                {status === "SUCCESS" ? <span className="success-msg">{t("contact_form_success")}</span> : <button type="submit" className="form-control submit-btn" placeholder={t("contact_form_submit")} disabled={true} id="contact-us-btn">{t("contact_form_submit")}</button>}
                                                {status === "ERROR" && <span className="error-msg">{t("contact_form_error")}</span>}
                                                {status === "EMPTY" && <span className="error-msg">{t("contact_form_empty")}</span>}

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> {/* card body */}
                        </div> {/* card */}
                    </div>
                )}
            </Translation>
        )
    }

    submitForm = (ev) => {
        ev.preventDefault();
        if ($(".text-field").val()) {
            const form = ev.target;
            const data = new FormData(form);
            const xhr = new XMLHttpRequest();
            xhr.open(form.method, form.action);
            xhr.setRequestHeader("Accept", "application/json");
            xhr.onreadystatechange = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) return;
                if (xhr.status === 200) {
                    form.reset();
                    this.setState({ status: "SUCCESS" });

                } else {
                    this.setState({ status: "ERROR" });
                }
            };
            xhr.send(data);

        } else {
            this.setState({ status: "EMPTY" });
        }
    }
}
