import React from 'react'
import './service.css'
import ServicesList from './components/servicesList';
import GemstoneReportBody from './components/gemstoneReportBody';
export default class gemstoneReportMiddle extends React.Component {
    render() {
        return (
            <div className="container-fluid gemstoneMiddle">
                <ServicesList />
                <div className="row mt-md-5">
                    <div className="col-12 p-0 ">
                        <GemstoneReportBody />
                    </div>
                </div>
            </div>
        )
    }
}
