import React from 'react'
import { Translation } from "react-i18next";

export default class Branch extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <Translation>
                {(t) => (
                    <div>
                        <div className="card">
                            <div className="card-header">
                                {t("contact_branch")}
                            </div>
                            <div className="card-body">
                                <form action="#">
                                    <div className="form-check">
                                        <input type="radio" className="branch-i" name="branch" value="r" onChange={props.locationPicker.bind(this)} />
                                        <label className=""> {t("contact_riyadh_branch")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input type="radio" className="branch-i" name="branch" value="j" onChange={props.locationPicker.bind(this)} />
                                        <label className="">{t("contact_jeddah_branch")} </label>
                                    </div>
                                </form>
                            </div> {/* card body */}
                        </div> {/* card */}
                    </div>
                )}
            </Translation>
        )
    }
}
