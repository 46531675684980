import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import i18next from "i18next";
const carousel = [
    {
      "media_title_en": "Beauty is in the eye",
      "media_title_2_en": "of the beholder.",
      "media_paragraph_en": "Quality is measured by experts.",
      "button_text_en": "our services",
      "button_link": "/Service",
      "media_title_ar": "الجمال",
      "media_title_2_ar": "في عين الناظر",
      "media_paragraph_ar": "يتم قياس الجودة من قبل الخبراء.",
      "button_text_ar": "خدماتنا",
      "Media": {
        "name": "Video No logo.mp4",
        "url": "/introVideo.mp4",
      }
    }
  ]
const Carsouel = () => {

    const [data, setData] = useState(carousel);
    async function fetchData() {
        const res = await fetch("https://admin.mtgl.com.sa/home-sliders");
        res
            .json()
            .then(res => setData(res))
    }

    // useEffect(() => {
    //     fetchData();
    // });
    if (data.length === 0) {
        return (
            <div>
                Loading ...
            </div>
        )
    }
    const url = "https://admin.mtgl.com.sa";

    return (
        <>
            {/* Carsouel */}

            <Carousel >
                {data.map(function (carsouel) {
                    const media = carsouel.Media
                    return (
                        <Carousel.Item className="carousel-caption-item" key={carsouel.id}>
                            <div className="player-wrapper">
                                <ReactPlayer
                                    url={media.url}
                                    className='react-player'
                                    playing
                                    loop
                                    muted
                                    width='100%'
                                    height='90%'
                                />
                            <Carousel.Caption className="carousel-caption-home col-12">

                                <h1 style={{
                                    lineHeight: 0.76,
                                    fontWeight: 'bold',
                                    fontSize: '80px',
                                    textTransform: 'capitalize',
                                }}>
                                    {i18next.languages[0] === "en" ? carsouel.media_title_en : carsouel.media_title_ar}
                                <br />
                                    {i18next.languages[0] === "en" ? carsouel.media_title_2_en : carsouel.media_title_2_ar}
                                </h1>
                                <p style={{
                                    fontSize: '20px',
                                }}>
                                    {i18next.languages[0] === "en" ? carsouel.media_paragraph_en : carsouel.media_paragraph_ar}
                                </p>

                                {carsouel.button_text_en.length === 0 || carsouel.button_text_ar.length === 0 || carsouel.button_link.length === 0 ? "" :
                                    <a href={carsouel.button_link}>
                                        <button style={{
                                            width: '229px',
                                            height: '61px',
                                            borderRadius: '15px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }} className="btn my-3 my-sm-0 navbar-btn text-uppercase" type="submit">
                                            {i18next.languages[0] === "en" ? carsouel.button_text_en : carsouel.button_text_ar}
                                        </button>
                                    </a>
                                }

                            </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </>
    )

}
export default Carsouel