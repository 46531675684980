import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './mobile.css';
import Footer from './footer';
import Header from './header';
import Home from './home';
import { Route, Switch } from 'react-router-dom';
import TheLaboratory from './theLaboratory';
import contact from './contact';
import ReportVerfiy from './reportVerfiy';
import Service from './service';
import RetailLookup from './retailLookup';
import DiamondReport from './diamondReport';
import GemstoneReport from './gemstoneReport';
import GemstoneReportMiddle from './gemstoneReportMiddle';
import JewelryReport from './jewelryReport';
import JewelryReportMiddle from './jewelryReportMiddle';
import DiamondSealing from './diamondSealing';
import ScreeningService from './screeningService';
import OnSiteTesting from './onSiteTesting';
import StandardReport from './gemstoneReport/standardReport'
import CardReport from './gemstoneReport/cardReport'
import StandardJewelryReport from './jewelryReports/standardJewelryReport'
import LargeJewelryReport from './jewelryReports/largeJewelryReport'
import PremiumJewelryReport from './jewelryReports/premiumJewelryReport'
import CardJewelryReport from './jewelryReports/cardJewelryReport'
import Helmet from "react-helmet";
import Terms from './components/terms'
import ReactGA from 'react-ga';
import RouteChangeTracker from "./routeChangeTracker";
import { useLocation } from 'react-router-dom';


ReactGA.initialize('G-79RJVTLT3E'); // add your tracking id here.
function App() {
  const location = useLocation();
  console.log(location.pathname)
  const [isDark, setIsDark] = React.useState(false);
  useEffect(() => {
  const isMatchedPath = ['/contact', '/ReportVerify', '/RetailLookup'].includes(location.pathname);
  setIsDark(isMatchedPath);
  }, [location.pathname]);
  const { i18n } = useTranslation();
  useEffect(() => {
    document.dir = i18n.dir();
    document.documentElement.lang = i18n.language;

  }, [i18n, i18n.language]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <>
      <RouteChangeTracker />
      <React.Fragment>
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>

       <Header  dark={isDark} onLanguageChange={(lang) =>
          i18n.changeLanguage(lang)
        } />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/TheLaboratory" component={TheLaboratory} />
          <Route path="/contact" component={contact} />
          <Route path="/Service" render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={Service} exact />
              <Route path={`${url}/DiamondReport`} component={DiamondReport} />
              <Route path={`${url}/GemstoneReport`} component={GemstoneReport} />
              <Route path={`${url}/JewelryReport`} component={JewelryReport} />
              <Route path={`${url}/DiamondSealing`} component={DiamondSealing} />
              <Route path={`${url}/ScreeningService`} component={ScreeningService} />
              <Route path={`${url}/OnSiteTesting`} component={OnSiteTesting} />
              <Route path={`${url}/GemstoneReportMiddle`} component={GemstoneReportMiddle} />
              <Route path={`${url}/GemstoneReportMiddle/StandardReport`} component={StandardReport} />
              <Route path={`${url}/GemstoneReportMiddle/CardReport`} component={CardReport} />

              <Route path={`${url}/JewelryReportMiddle`} component={JewelryReportMiddle} />

              <Route path={`${url}/JewelryReportMiddle/PremiumJewelryReport`} component={PremiumJewelryReport} />
              <Route path={`${url}/JewelryReportMiddle/StandardJewelryReport`} component={StandardJewelryReport} />
              <Route path={`${url}/JewelryReportMiddle/LargeJewelryReport`} component={LargeJewelryReport} />
              <Route path={`${url}/JewelryReportMiddle/CardJewelryReport`} component={CardJewelryReport} />
            </>
          )} />
          <Route path="/RetailLookup" component={RetailLookup} />
          <Route path="/ReportVerify" component={ReportVerfiy} />
          <Route path="/Terms" component={Terms} />
        </Switch>
        <Footer />
      </React.Fragment>
    </ >
  );
}

export default App;
