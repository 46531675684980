import React from 'react';
import ServicesList from './components/servicesList'
import Helmet from "react-helmet";
import i18next from "i18next";
import ReactPlayer from 'react-player'
import Loading from './components/Loading';
class DiamondSealing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            links: []
        };
    }
    componentDidMount() {
        this.fetcData();
        this.fetcLinks();
    }
    fetcData() {
        fetch(`https://admin.mtgl.com.sa/diamond-sealing`)
            .then(response => response.json())
            .then(data =>
                this.setState({ data }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    fetcLinks() {
        fetch(`https://admin.mtgl.com.sa/service`)
            .then(response => response.json())
            .then(links =>
                this.setState({ links }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    render() {
        const data = this.state.data
        const links = this.state.links
        if (data.length === 0) {
            return (
                <Loading />
            )
        }
        const url = "https://admin.mtgl.com.sa";
        return (
            <div className="container-fluid diamondSealing">
                <Helmet>
                    <title>
                        {i18next.languages[0] === "en" ? links.service_list4_en : links.service_list4_ar}
                    </title>
                    <meta name="description"
                        content={i18next.languages[0] === "en" ? data.paragraph_en : data.paragraph_ar}
                    />
                </Helmet>
                <ServicesList />
                <div className="row mt-md-5">
                    <div className="col-12 p-0 ">
                        <div className=" diamond-body service-body">

                            <div className="container p-md-5">
                                <div className="row">
                                    <div className="col-md-12 d-none d-md-flex justify-content-start">
                                        <div className="arrow-head">
                                            <span> &#9660;</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6 ">

                                        <h1 >
                                            {i18next.languages[0] === "en" ? links.service_list4_en : links.service_list4_ar}
                                        </h1>
                                        <p className="transcript">
                                            {i18next.languages[0] === "en" ? data.small_paragraph_en : data.small_paragraph_ar}
                                        </p>
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 ">
                                                <p>
                                                    {i18next.languages[0] === "en" ? data.paragraph_en : data.paragraph_ar}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 services-img">
                                        {/* for pic */}
                                        <img src={url + data.report_image[0].url} className="img-fluid" alt={data.report_image[0].name} />
                                    </div>
                                    <div className="player-wrapper-service">
                                        <ReactPlayer
                                            url={url + data.video[0].url}
                                            className='react-player'
                                            playing
                                            controls={true}
                                            loop
                                            muted
                                            width='100%'
                                            height='100%'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DiamondSealing;
