import React from 'react'
import ServicesList from './components/servicesList';
import JewelryBody from './components/jewelryReportBody';
export default class jewelryReportMiddle extends React.Component {

    render() {
        return (
            <div className="container-fluid jewelryMiddle">
                <ServicesList />
                <div className="row mt-md-5">
                    <div className="col-12 p-0 ">
                        <JewelryBody />
                    </div>
                </div>
            </div>
        )
    }
}
