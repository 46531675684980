import React from 'react'
import '../service.css'
import i18next from "i18next";
import { NavLink } from 'react-router-dom';
export default class gemstoneReportBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            links: []
        };
    }
    componentDidMount() {
        window.scrollTo(80, 0)
        this.fetcData();
        this.fetcLinks();
    }

    fetcData() {
        fetch(`https://admin.mtgl.com.sa/gemstone-report`)
            .then(response => response.json())
            .then(data =>
                this.setState({ data }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    fetcLinks() {
        fetch(`https://admin.mtgl.com.sa/service`)
            .then(response => response.json())
            .then(links =>
                this.setState({ links }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    render() {
        const data = this.state.data
        const links = this.state.links
        return (
            <div>
                <div className="diamond-body service-body" >
                    <div className="container p-md-5">
                        <div className="row">
                            <div className="col-md-12 d-none d-md-flex justify-content-center">
                                <div className="arrow-head">
                                    <span> &#9660;</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 ">

                                <h1>
                                    {i18next.languages[0] === "en" ? links.service_list2_en : links.service_list2_ar}
                                </h1>
                                <p className="transcript">
                                    {i18next.languages[0] === "en" ? data.small_paragraph_en : data.small_paragraph_ar}
                                </p>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 ">
                                        <p>
                                            {i18next.languages[0] === "en" ? data.paragraph1_en : data.paragraph1_ar}
                                        </p>
                                        <div className="li-diamond">
                                            <p>
                                                {i18next.languages[0] === "en" ? data.list_title_en : data.list_title_ar}
                                            </p>
                                            <ul>
                                                <li>
                                                    {i18next.languages[0] === "en" ? data.list_item1_en : data.list_item1_ar}
                                                </li><br />
                                                <li>
                                                    {i18next.languages[0] === "en" ? data.list_item2_en : data.list_item2_ar}
                                                </li><br />
                                                <li>
                                                    {i18next.languages[0] === "en" ? data.list_item3_en : data.list_item3_ar}
                                                </li><br />
                                                <li>
                                                    {i18next.languages[0] === "en" ? data.list_item4_en : data.list_item4_ar}
                                                </li><br />
                                                <li>
                                                    {i18next.languages[0] === "en" ? data.list_item5_en : data.list_item5_ar}
                                                </li><br />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row Gemstone-body">
                    <div className="col-12">
                        {/* <div className=" "> */}
                        <div className="row">
                            <NavLink to="/Service/GemstoneReportMiddle/StandardReport" className=" col-md-6 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                {i18next.languages[0] === "en" ? data.standard_gemstone_en : data.standard_gemstone_ar}
                            </NavLink>
                            <NavLink to="/Service/GemstoneReportMiddle/CardReport" className=" col-md-6 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                {i18next.languages[0] === "en" ? data.card_gemstone_en : data.card_gemstone_ar}
                            </NavLink>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>

        )
    }
}
