import React from 'react'
import '../service.css';
import { Translation } from "react-i18next";
import axios from "axios"
import i18next from "i18next";
import { NavLink } from 'react-router-dom';
export default class servicesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/service")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        return (
            <Translation>
                {(t) => (
                    <>
                        <div className="row">
                            <div className="col-12 p-0 ">
                                <div className="service-container">
                                    <div className="services-clear"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="service-body">
                                    <div className="container-fluid ">
                                        <div className="row">
                                            <NavLink to="/Service/DiamondReport" className=" shadow-sm col-12 col-md-4 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                                {i18next.languages[0] === "en" ? data.service_list1_en : data.service_list1_ar}
                                            </NavLink>
                                            <NavLink to="/Service/GemstoneReport" className=" shadow-sm col-12 col-md-4 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                                {i18next.languages[0] === "en" ? data.service_list2_en : data.service_list2_ar}
                                            </NavLink>
                                            <NavLink to="/Service/JewelryReport" className=" shadow-sm col-12 col-md-4 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                                {i18next.languages[0] === "en" ? data.service_list3_en : data.service_list3_ar}
                                            </NavLink>
                                        </div>
                                        <div className="row">
                                            <NavLink to="/Service/DiamondSealing" className=" shadow-sm col-12 col-md-4 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                                {i18next.languages[0] === "en" ? data.service_list4_en : data.service_list4_ar}
                                            </NavLink>
                                            <NavLink to="/Service/ScreeningService" className=" shadow-sm col-12 col-md-4 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                                {i18next.languages[0] === "en" ? data.service_list5_en : data.service_list5_ar}
                                            </NavLink>
                                            <NavLink to="/Service/OnSiteTesting" className=" shadow-sm col-12 col-md-4 col-sm-12 h-head bg-white" activeClassName="nav-link--active">
                                                {i18next.languages[0] === "en" ? data.service_list6_en : data.service_list6_ar}
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Translation>
        )
    }
}
