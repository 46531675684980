import React from 'react';
import 'react-bootstrap';
import { Card } from 'semantic-ui-react'
import { Translation } from "react-i18next";
import Carsouel from './components/carsouel';
import axios from "axios"
import i18next from "i18next";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:  [{
                "gemstone_title_en": "Every gemstone is unique - and tells a story...",
                "gemstone_paragraph1_en": "In our laboratories, we closely examine your gemstone and unearth that story with our world-class expertise. Every facet, inclusion and sparkling surface is carefully catalogued and painstakingly verified. It's beauty may be subjective, but it's value can be measured by a specific set of characteristics.",
                "gemstone_paragraph2_en": "If you're a jewelry retailer, you know your customers are keenly seeking quality and want to be reassured that what they are purchasing is the real thing. When you purchase a house you receive a deed. A vehicle comes with a title and registration. It only makes sense to document something as precious as a gem purchase with an official accreditation.",
                "gemstone_paragraph3_en": "Our mission is to create in-depth reports with detailed descriptions of your diamonds, colored stones and jewelry. These reports are a mark of assurance for any buyer who wants to purchase diamonds confidently.",
                "risk_header_en": "The Risk of Buying Uncertified Gems",
                "risk_paragraph1_en": "Any time a diamond, gemstone or piece of jewelry is sold without a verifying certificate, there's a risk involved. You can only speculate on the quality or authenticity of the diamond - and it may not be as advertised. Certified diamonds and gemstones are documented for their quality. Without this documentation, it can be difficult for buyers to be sure of what they are investing in.",
                "risk_paragraph2_en": "The growth of e-commerce has significantly increased the importance of certified diamonds in today's market.There's always a risk when buying a diamond 'sight- unseen' and a non-certified diamond is almost impossible to sell online.Therefore, it's essential for jewelry retailers to have accredited gem certification for every stone and jewelry piece they buy.",
                "risk_paragraph3_en": "We do not engage in any commercial aspect of the gemstone and jewelry trade whatsoever so you can rest assured we will offer a completely unbiased report.",
                "how_it_works_en": "How It Works",
                "how_it_works_paragraph1_en": "Without a certification, you can never be certain of the authenticity of your gems.Therefore, it's essential to get a verification report whenever selling or buying.This will save you from the risk of purchasing an inauthentic gemstone.",
                "how_it_works_paragraph2_en": "Ready to get your gemological report? It's very easy to get started. Here are the steps to take:",
                "how_it_works_card1_header_en": "Visit us",
                "how_it_works_card1_description_en": "Visit any of our laboratories",
                "how_it_works_card2_header_en": "Submit piece",
                "how_it_works_card2_description_en": "Submit your piece/s",
                "how_it_works_card3_header_en": "Get reports",
                "how_it_works_card3_description_en": "Get your reports",
                "how_it_works_paragraph3_en": "After we have assessed your gemstone, you'll receive a high-quality report that you can check online at any time.",
                "satisfaction_header_en": "Satisfaction Guarantee",
                "satisfaction_paragraph1_en": "Our priority at Metal Testing Gemological Laboratories is your satisfaction. This means that we stand by our reports completely. No matter what, we are committed to ensuring you are satisfied with the quality of your gemological report.",
                "satisfaction_paragraph2_en": "If you have any issues with your report, reach out to us and we will solve it. Our customer service team is here for you and you can trust us to make sure",
                "specialists_in_detailsHeader_en": "Specialists in The Details",
                "specialists_in_details_paragraph1_en": "At MT Gemological Laboratories, you'll receive a precise, accurate, accredited report for any gemstone.Our reports are expert statements of the gem's identity and quality characteristics.Gemological grading reports are essential for accurately reporting and verifying vital information about diamonds and other gemstones.",
                "specialists_in_details_paragraph2_en": "Due to the increasingly sophisticated laboratory-grown gems on the market today, it's more important than ever to examine gemstones carefully with highly advanced scientific instruments and the expertise of a gemologist.When you purchase a gemstone verified by one of our reports, you can rest assured knowing that it has been thoroughly checked by one of our experts.",
                "gemstoneHeader_ar": "كل حجر كريم فريد من نوعه، ويحكي قصة",
                "gemston_paragraph1_ar": "في مختبراتنا، نعيد إكتشاف كل حجر بطريقتنا الخاصة، خبرة عالمية تؤهلنا لفحص الأحجار الكريمة عن كثب، كل وجه من أوجه الحجر الكريم يتم فهرسته بعناية والتحقق من صحته، قد يكون جماله غير موضوعي، ولكن يمكن قياس قيمته الحقيقية من خلال مجموعة محددة من الخصائص.",
                "gemston_paragraph2_ar": "إذا كنت مالك متاجر مجوهرات، فأنت تعلم أن عملائك يسعون بشدة للحصول على الجودة وتريد أن تطمئن إلى أن مايتم بيعه لديك هو الشيء الحقيقي، كل ثمين يتم توثيقة بإثبات وإعتماد رسمي، منازل، سيارات وأي ممتلكات مهمة يتم توثيقها وكذلك الأحجار الكريمة قد تساوي ثروة من المنطقي توثيقها بتقارير معتمدة..",
                "gemston_paragraph3_ar": "مهمتنا هي إنشاء تقارير قيمة تحتوي وصف دقيق وتفصيلي للألماس والأحجار الكريمة والمجوهرات. هذه التقارير هي علامة ضمان لأي مشتر يريد شراء الألماس بثقة.",
                "risk_header_ar": "مخاطر شراء الأحجار الكريمة غير المعتمدة",
                "risk_paragraph1_ar": "تقع المخاطرة حال تم بيع الألماس أو الأحجار الكريمة أو قطعة مجوهرات بدون تقرير فحص معتمد، التكهن بجودة أو حقيقة الألماس ليست عملية موثوقة، وقد لا يكون كما هو معلن ومعروف حول الألماسة . بدون هذه الوثائق للألماس والأحجار الكريمة ذات الجودة، قد يكون من الصعب على المشترين التأكد مما يستثمرون فيه.",
                "risk_paragraph2_ar": "لقد أدى نمو التجارة الإلكترونية إلى زيادة أهمية الألماس المعتمد بشكل  كبير في سوق اليوم ، فهناك دائماً خطر عند شراء الألماس 'غير الملموس' والألماس غير المعتمد يكاد يكون من المستحيل بيعه عبر الإنترنت لفقدان عنصر الثقة، لذلك من الضروري لبائعي المجوهرات أن يوثق الأحجار الكريمة والمجوهرات ليتم إعتمادها والثقة بمصدرها لبناء الثقة مع العملاء..",
                "risk_paragraph3_ar": "نحن لا نتحيز لأي جانب في مجال تجارة الأحجار الكريمة والمجوهرات على الإطلاق، لذا يمكنك أن تطمئن إلى أننا سنقدم تقارير محايدة تماماً.",
                "how_it_works_ar": "كيف نعمل",
                "how_it_works_paragraph1_ar": "بدون تقرير فحص معتمد، لا يمكنك أبدًا التأكد من حقيقة الأحجار الكريمة الخاصة بك، لذلك من الضروري الحصول على تقرير تحقق عند البيع أو الشراء ، فهذا سيوفر عليك مخاطر شراء الأحجار الكريمة غير الأصلية.",
                "how_it_works_paragraph2_ar": "هل أنت جاهز للحصول على تقرير الأحجار الكريمة الخاص بك؟ من السهل جداً البدء. فيما يلي الخطوات التي يجب اتخاذها..",
                "how_it_works_paragraph3_ar": "بعد أن نقوم بتقييم الأحجار الكريمة الخاصة بك، ستتلقى تقريراً معتمد وموثق عبر الإنترنت يمكنك التحقق منه في أي وقت.",
                "how_it_works_card1_header_ar": "قم بزيارتنا",
                "how_it_works_card1_description_ar": "قم بزيارة أي من فروع مختبراتنا",
                "how_it_works_card2_header_ar": "إرسال القطعة",
                "how_it_works_card2_description_ar": "قم بإرسال الأحجار او المجوهرات",
                "how_it_works_card3_header_ar": "أحصل على التقارير",
                "how_it_works_card3_description_ar": " أحصل على تقريرك الخاص",
                "satisfaction_header_ar": "نضمن لك الرضا",
                "satisfaction_paragraph1_ar": "أولويتنا في مختبرات فحص المعادن هي الجودة والموثوقة والحياد وهو ما يضمن رضاك الكامل عن خدماتنا، وهذا يعني أننا نقف إلى جانب تقاريرنا تماماً. نحن ملتزمون بضمان تقديم الأفضل دائماً.",
                "satisfaction_paragraph2_ar": "فريق خدمة العملاء لدينا هنا من أجلك، يمكنك الإتصال بنا عند وجود أي شكوى او مشكلة..",
                "specialists_in_detailsHeader_ar": "متخصصون في التفاصيل",
                "specialists_in_details_paragraph1_ar": "في مختبرات فحص المعادن ستتلقى تقريراً دقيق ومعتمد عن أي حجر كريم، تقاريرنا عبارة عن هوية تفصيلية حول بيانات الحجر الكريم وجودته بواسطة خبراء الأحجار الكريمة. وتعد تقارير تصنيف الأحجار الكريمة ضرورية للمعرفة بدقة والتحقق من المعلومات الأساسية حول الألماس والأحجار الكريمة الأخرى",
                "specialists_in_details_paragraph2_ar": "نظراً للتطور المتزايد في الأحجار الكريمة المزروعة في المختبرات بالسوق اليوم، فمن المهم أكثر من أي وقت مضى فحص الأحجار الكريمة بعناية باستخدام أدوات متطورة للغاية وخبرة مختصين الأحجار الكريمة. مما يبعث للاطمئنان أن كل حجر لا يمكن أن يعبر بسهولة دون فحص دقيق من خبرائنا.",
                "video": [
                  {
                    "name": "Video No logo.mp4",
                    "url": "/Video_No_logo_194c443a00.mp4",
                  }
                ],
                "risk_image": [
                  {
                    "name": "Photo2.jpeg",
                    "formats": {
                      "thumbnail": {
                        "name": "thumbnail_Photo2.jpeg",
                        "url": "/uploads/thumbnail_Photo2_49f2d67ae7.jpeg"
                      },
                      "large": {
                        "name": "large_Photo2.jpeg",
                        "url": "/uploads/large_Photo2_49f2d67ae7.jpeg"
                      },
                      "medium": {
                        "name": "medium_Photo2.jpeg",
                        "url": "/uploads/medium_Photo2_49f2d67ae7.jpeg"
                      },
                      "small": {
                        "name": "small_Photo2.jpeg",
                        "url": "/uploads/small_Photo2_49f2d67ae7.jpeg"
                      }
                    },
                    "url": "/uploads/Photo2_49f2d67ae7.jpeg",
                  }
                ],
                "how_it_works_card1_image": [
                  {
                    "name": "location.png",
                    "formats": {
                      "thumbnail": {
                        "name": "thumbnail_location.png",
                        "url": "/uploads/thumbnail_location_9f92e39ada.png"
                      },
                      "small": {
                        "name": "small_location.png",
                        "url": "/uploads/small_location_9f92e39ada.png"
                      }
                    },
                    "url": "/uploads/location_9f92e39ada.png",
                  }
                ],
                "how_it_works_card2_image": [
                  {
                    "id": 9,
                    "name": "21-512.png",
                    "formats": {
                      "thumbnail": {
                        "name": "thumbnail_21-512.png",
                        "url": "/uploads/thumbnail_21_512_400bf9d3b6.png"
                      },
                      "small": {
                        "name": "small_21-512.png",
                        "url": "/uploads/small_21_512_400bf9d3b6.png"
                      }
                    },
                    "hash": "21_512_400bf9d3b6",
                    "url": "/uploads/21_512_400bf9d3b6.png",
                  }
                ],
                "how_it_works_card3_image": [
                  {
                    "name": "report.png",
                    "formats": {
                      "thumbnail": {
                        "name": "thumbnail_report.png",
                        "url": "/uploads/thumbnail_report_3459cbc2ab.png"
                      },
                      "small": {
                        "name": "small_report.png",
                        "url": "/uploads/small_report_3459cbc2ab.png"
                      }
                    },
                    "url": "/uploads/report_3459cbc2ab.png",
                  }
                ],
                "satisfaction_image": [
                  {
                    "name": "Photo1.jpeg",
                    "formats": {
                      "thumbnail": {
                        "name": "thumbnail_Photo1.jpeg",
                        "url": "/uploads/thumbnail_Photo1_b8ddfd75d5.jpeg"
                      },
                      "large": {
                        "name": "large_Photo1.jpeg",
                        "url": "/uploads/large_Photo1_b8ddfd75d5.jpeg"
                      },
                      "medium": {
                        "name": "medium_Photo1.jpeg",
                        "url": "/uploads/medium_Photo1_b8ddfd75d5.jpeg"
                      },
                      "small": {
                        "name": "small_Photo1.jpeg",
                        "path": null,
                        "url": "/uploads/small_Photo1_b8ddfd75d5.jpeg"
                      }
                    },
                    "url": "/uploads/Photo1_b8ddfd75d5.jpeg",
                  }
                ],
                "specialists_in_details_image": [
                  {
                    "name": "mockups-design-com.jpeg",
                    "formats": {
                      "thumbnail": {
                        "name": "thumbnail_mockups-design-com.jpeg",
                        "url": "/uploads/thumbnail_mockups_design_com_b79de0917d.jpeg"
                      },
                      "large": {
                        "name": "large_mockups-design-com.jpeg",
                        "url": "/uploads/large_mockups_design_com_b79de0917d.jpeg"
                      },
                      "medium": {
                        "name": "medium_mockups-design-com.jpeg",
                        "path": null,
                        "url": "/uploads/medium_mockups_design_com_b79de0917d.jpeg"
                      },
                      "small": {
                        "name": "small_mockups-design-com.jpeg",
                        "url": "/uploads/small_mockups_design_com_b79de0917d.jpeg"
                      }
                    },
                    "url": "/uploads/mockups_design_com_b79de0917d.jpeg",
                  }
                ]
              }]
        }
    }
    render() {
        const data  = this.state.data[0];
        const url = "https://admin.mtgl.com.sa";
        return (
            <Translation>
                {(t) => (
                    <div className="container-fluid" >
                        <div className="row">
                            <Carsouel />
                        </div>

                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="gem-bg gemstory">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <h2>
                                                    {i18next.languages[0] === "en" ? data.gemstone_title_en : data.gemstoneHeader_ar}
                                                </h2>
                                            </div>
                                            <div className="col-12 ">
                                                <div className="gemicon">
                                                    <img src="https://i.ibb.co/7Wrcdhp/Diamonds-Gold.png" className="img-fluid d-block mx-auto" alt="Diamonds-Gold" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="gembody">

                                                    <p>
                                                        {i18next.languages[0] === "en" ? data.gemstone_paragraph1_en : data.gemston_paragraph1_ar}
                                                    </p>
                                                    <p>
                                                        {i18next.languages[0] === "en" ? data.gemstone_paragraph2_en : data.gemston_paragraph2_ar}
                                                    </p>
                                                    <p>
                                                        {i18next.languages[0] === "en" ? data.gemstone_paragraph3_en : data.gemston_paragraph3_ar}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 p-0">
                                <div className="info-container risk ">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-5 ">
                                                <div className="lab-sci-home risk-image" >
                                                    <img src={url + data.risk_image[0].url} alt={data.risk_image[0].name} />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-5 ml-0 text-center ">
                                                <h2 className="pb-3 font-weight-bold">
                                                    {i18next.languages[0] === "en" ? data.risk_header_en : data.risk_header_ar}
                                                </h2>
                                                {/* <div className="container"> */}
                                                <p className=" risk-body ">
                                                    {i18next.languages[0] === "en" ? data.risk_paragraph1_en : data.risk_paragraph1_ar}
                                                </p>
                                                <p className=" risk-body">
                                                    {i18next.languages[0] === "en" ? data.risk_paragraph2_en : data.risk_paragraph2_ar}
                                                </p>
                                                <p className="risk-body ">
                                                    {i18next.languages[0] === "en" ? data.risk_paragraph3_en : data.risk_paragraph3_ar}
                                                </p>
                                                {/* </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  info-container work */}
                        <div className="row ">
                            <div className="col-12 p-0">
                                <div className=" work info-container">
                                    <div className="container">
                                        <div className="row">
                                            <div className="work-head ">
                                                <div className="row mb-2">
                                                    <div className="col-12 col-md-6 d-flex   justify-content-md-start">
                                                        <h2>
                                                            {i18next.languages[0] === "en" ? data.how_it_works_en : data.how_it_works_ar}
                                                        </h2>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    <div className="col-6 col-md-3 col-sm-9 d-flex justify-content-md-end mt-3 mt-md-0 ">
                                                        <a href="/Service" className="btn submit-btn"  >
                                                            {t("home_ourServices")}
                                                        </a>
                                                    </div>
                                                </div>
                                                <p className="col-md-8">
                                                    {i18next.languages[0] === "en" ? data.how_it_works_paragraph1_en : data.how_it_works_paragraph1_ar}
                                                </p>
                                                <p className="col-md-8">
                                                    {i18next.languages[0] === "en" ? data.how_it_works_paragraph2_en : data.how_it_works_paragraph2_ar}
                                                </p>
                                            </div>
                                            <div className=" col-12 d-flex justify-content-center text-center mt-4 mb-4 ">

                                                <Card.Group>
                                                    <div className="col-12 col-md-4 col-lg-4 mx-auto  d-flex justify-content-center" >
                                                        <Card className="home-card">
                                                            <Card.Content>
                                                                <Card.Meta>
                                                                    <img src={url + data.how_it_works_card1_image[0].url} className="fa-stack workicon" alt={data.how_it_works_card1_image[0].name} />
                                                                </Card.Meta>
                                                                <br />
                                                                <Card.Header>
                                                                    {i18next.languages[0] === "en" ? data.how_it_works_card1_header_en : data.how_it_works_card1_header_ar}
                                                                </Card.Header>
                                                                <Card.Description>
                                                                    {i18next.languages[0] === "en" ? data.how_it_works_card1_description_en : data.how_it_works_card1_description_ar}
                                                                </Card.Description>
                                                            </Card.Content>
                                                        </Card>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-center">

                                                        <Card className="home-card">
                                                            <Card.Content>

                                                                <Card.Meta>
                                                                    <img src={url + data.how_it_works_card2_image[0].url} className="fa-stack workicon" alt={data.how_it_works_card2_image[0].name} />
                                                                </Card.Meta>
                                                                <br />
                                                                <Card.Header>
                                                                    {i18next.languages[0] === "en" ? data.how_it_works_card2_header_en : data.how_it_works_card2_header_ar}
                                                                </Card.Header>
                                                                <Card.Description>
                                                                    {i18next.languages[0] === "en" ? data.how_it_works_card2_description_en : data.how_it_works_card2_description_ar}
                                                                </Card.Description>
                                                            </Card.Content>

                                                        </Card>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-center">
                                                        <Card className="home-card">
                                                            <Card.Content>

                                                                <Card.Meta>
                                                                    <img src={url + data.how_it_works_card3_image[0].url} className="fa-stack workicon" alt={data.how_it_works_card3_image[0].name} />
                                                                </Card.Meta>
                                                                <br />
                                                                <Card.Header>
                                                                    {i18next.languages[0] === "en" ? data.how_it_works_card3_header_en : data.how_it_works_card3_header_ar}
                                                                </Card.Header>
                                                                <Card.Description>
                                                                    {i18next.languages[0] === "en" ? data.how_it_works_card3_description_en : data.how_it_works_card3_description_ar}
                                                                </Card.Description>
                                                            </Card.Content>

                                                        </Card>
                                                    </div>
                                                </Card.Group>
                                            </div>
                                            <div className="col-12 work-end  mb-5 mt-5">
                                                {i18next.languages[0] === "en" ? data.how_it_works_paragraph3_en : data.how_it_works_paragraph3_ar}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* satisfaction */}
                        <div className="row ">
                            <div className="col-12 ">
                                <div className="info-container ">
                                    <div className="row">
                                        <div className="col-md-4 text-center container">
                                            <h2 className="pb-3 sat-h">
                                                {i18next.languages[0] === "en" ? data.satisfaction_header_en : data.satisfaction_header_ar}
                                            </h2>
                                            <p className=" satisfaction-body">
                                                {i18next.languages[0] === "en" ? data.satisfaction_paragraph1_en : data.satisfaction_paragraph1_ar}
                                            </p>
                                            <p className=" satisfaction-body">
                                                {i18next.languages[0] === "en" ? data.satisfaction_paragraph2_en : data.satisfaction_paragraph2_ar}
                                            </p>
                                        </div>
                                        <div className="col-md-5 container text-center">
                                            <div className="lab-sci-home2 satis-image">
                                                <img src={url + data.satisfaction_image[0].url} alt={data.satisfaction_image[0].name} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="row ">
                            <div className="col-12 px-0 px-sm-0 px-md-auto details">
                                <div className="info-container container details">
                                    <div className="row">
                                        <div className="col-md-5 container text-right">
                                            <img className="details-image" src={url + data.specialists_in_details_image[0].url} alt={data.specialists_in_details_image[0].name} ></img>
                                        </div>
                                        <div className="col-md-6  container">
                                            <h2 className="pb-3 det-h2">
                                                {i18next.languages[0] === "en" ? data.specialists_in_detailsHeader_en : data.specialists_in_detailsHeader_ar}
                                            </h2>
                                            <p className="details-body">
                                                {i18next.languages[0] === "en" ? data.specialists_in_details_paragraph1_en : data.specialists_in_details_paragraph1_ar}
                                            </p>
                                            <p className="details-body">
                                                {i18next.languages[0] === "en" ? data.specialists_in_details_paragraph2_en : data.specialists_in_details_paragraph2_ar}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                )
                }
            </Translation>
        )

    }
}
export default Home;
