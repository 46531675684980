import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/map-marker';
import i18next from "i18next";
import './map.css';
/* location marker */
const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)
// const API_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const Map = ({ location, zoomLevel }) => {
  const currentLanguage = i18next.languages[0];
  return (

  <div className="map">


    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDjnzHouBz_ZxvcczRQh5cZTqSgusqtVl0' }}
        center={{lat:location.lat, lng: location.lng}}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address[currentLanguage]}
        />
      </GoogleMapReact>
    </div>
  </div>
)
}


export default Map;