import React from "react"
import './service.css'
import ServicesList from './components/servicesList';
import GemstoneReportBody from './components/gemstoneReportBody';
import Helmet from "react-helmet";
import axios from "axios"
import i18next from "i18next";
class GemstoneReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/gemstone-report")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        return (
            <div className="container-fluid gemstoneReport">
                <Helmet>
                    <title>
                        {i18next.languages[0] === "en" ? data.title_en : data.title_ar}
                    </title>
                    <meta name="description"
                        content={i18next.languages[0] === "en" ? data.paragraph1_en : data.paragraph1_ar} />
                </Helmet>
                <ServicesList />
                <div className="row mt-md-5">
                    <div className="col-12 p-0 ">
                        <GemstoneReportBody />
                    </div>
                </div>
            </div>
        )
    }
}

export default GemstoneReport;
