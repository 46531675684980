import React from "react";
import 'react-bootstrap';
import Subscribe from "./components/subscribe";
import Social from "./components/social";
import i18next from "i18next";
import { Translation } from "react-i18next";
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            links: []
        };
    }
    componentDidMount() {
        this.fetcData();
        this.fetcLinks();
    }
    fetcData() {
        fetch(`https://admin.mtgl.com.sa/footer`)
            .then(response => response.json())
            .then(data =>
                this.setState({ data }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    fetcLinks() {
        fetch(`https://admin.mtgl.com.sa/footer-links`)
            .then(response => response.json())
            .then(links =>
                this.setState({ links }))
            .catch(error => this.setState({ error, isLoading: false }));
    }
    render() {
        const data = this.state.data
        const links = this.state.links
        return (
            <Translation>
                {(t) => (
                    <div className="bg-green mb-0">
                        <footer className="font-small footer  bg-green mb-0 " bg="green">
                            <div className="container bg-b py-5">
                                <div className="row bg-green">


                                    <div className="col-12 col-md-4">
                                        {/* mtgl */}
                                        <div className="row ">
                                            <div className="col-12 d-flex justify-content-center order-last order-md-first ">
                                                <a href="/">
                                                    <img src="https://i.ibb.co/YhJq62m/Metal-Testing-Horizontal-Logo-white.png" alt="Logo" className="img-fluid  mt-5 mt-md-0" />
                                                </a>
                                            </div>

                                            <div className="col-12 order-first order-md-last">
                                                <div className="row "  >
                                                    <div className="col-5 col-md-12">
                                                        <p className="footer_p ">
                                                            {i18next.languages[0] === "en" ? data.location_text1_en : data.location_text1_ar}
                                                        </p>
                                                    </div>
                                                    <div className="col-2 col-md-none footer-divider">
                                                    </div>
                                                    <div className="col-5 col-md-12">
                                                        <p className="footer_p">
                                                            {i18next.languages[0] === "en" ? data.location_text2_en : data.location_text2_ar}
                                                        </p>
                                                    </div>
                                                    <div className="col-5 col-md-12">
                                                        <p className="footer_p">
                                                            {i18next.languages[0] === "en" ? data.location_text3_en : data.location_text3_ar}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-none d-md-block col-md-3 footer-link">
                                        <div>
                                            <ul className="navbar-nav text-uppercase">
                                                {links.map(function (link) {
                                                    return (
                                                        <a key={link.id} href={link.link}>
                                                            <li className="nav-link">
                                                                {i18next.languages[0] === "en" ? link.name_en : link.name_ar}
                                                            </li>
                                                        </a>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <Subscribe /> */}

                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6  social">
                                        <ul className=" icon-footer">
                                            <Social />
                                        </ul>
                                    </div>
                                    <div className="col-12 d-none d-md-block col-md-6">
                                        <div className="iconlog">
                                            <a href="/">
                                                <img src="https://i.ibb.co/Bg1g4bn/Metal-Testing-Crown-Gold.png" alt="Logo" width="50px" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <hr className="w-100 clearfix d-none d-md-block" />
                                <div className="row footer-copyright text-center py-3">

                                    <div className="col-12 col-md-4 text-white">
                                        <p>
                                            {i18next.languages[0] === "en" ? data.copyright_en : data.copyright_ar}
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="/Terms" className=" terms-a">
                                            {i18next.languages[0] === "en" ? data.terms_en : data.terms_ar}
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://flows.sa/" style={{ cursor: 'pointer' }}>
                                            {t("footer_site_by")}
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </footer>
                    </div>
                )}
            </Translation>
        )
    }
}



export default Footer;
