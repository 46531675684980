import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Translation } from "react-i18next";
export default class RetailerSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ''
        }
    }

    render() {
        const props = this.props;
        return (
            <Translation>
                {(t) => (
                    <div>
                        <div className="input-group search-field">
                            <input type="text" className="form-control " placeholder={t("search")} onChange={props.handleSearch.bind(this)} />
                            <FontAwesomeIcon icon={faSearch} style={{ color: 'gray' }} className="mt-2 mr-3" />
                        </div>
                    </div>
                )}
            </Translation>
        )
    }
}
