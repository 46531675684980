import React from 'react';
import { Translation } from "react-i18next";
import Helmet from "react-helmet";
import axios from "axios"
import i18next from "i18next";
import ReactPlayer from 'react-player'
import Loading from '../components/Loading';
class LargeJewelryReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/large-jewelry-report")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        if (data.length === 0) {
            return (
                <Loading />
            )
        }
        const url = "https://admin.mtgl.com.sa";
        return (
            <Translation>
                {(t) => (
                    <div className="container-fluid ">
                        <Helmet>
                            <title>
                                {i18next.languages[0] === "en" ? data.title_en : data.title_ar}
                            </title>
                            <meta name={i18next.languages[0] === "en" ? data.paragraph1_en : data.paragraph1_ar}
                                content="Helmet application" />
                        </Helmet>
                        <div className="row">
                            <div className="col-12 p-0 ">
                                <div className=" GemstoneReport service-body">
                                    <div className="Gemstone-body row pt-5">
                                    </div>
                                    <div className="standard-jewlery-report-main">
                                        <div className="standard-head col-12 col-md-8 ">
                                            <p className="MT transcript">
                                                {i18next.languages[0] === "en" ? data.mt_en : data.mt_ar}
                                            </p>
                                            <h2 className="mt-0">
                                                {i18next.languages[0] === "en" ? data.title_en : data.title_ar}
                                            </h2>
                                            <p className=" transcript">
                                                {i18next.languages[0] === "en" ? data.small_paragraph_en : data.small_paragraph_ar}
                                            </p>
                                            <p className="stand-sec-p">
                                                {i18next.languages[0] === "en" ? data.paragraph1_en : data.paragraph1_ar}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="Details ">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 col-md-8 services-img ">
                                                    <img src={url + data.report_image1[0].url} className="img-fluid" alt={data.report_image1[0].name} />
                                                </div>
                                                <div className="col-12 col-md-4 detail-body ">
                                                    <h1>
                                                        {i18next.languages[0] === "en" ? data.detail_title_en : data.detail_title_ar}
                                                    </h1>
                                                    <p>
                                                        {i18next.languages[0] === "en" ? data.list_title_en : data.list_title_ar}
                                                    </p>
                                                    <li>
                                                        {i18next.languages[0] === "en" ? data.list_item1_en : data.list_item1_ar}
                                                    </li><br />
                                                    <li>
                                                        {i18next.languages[0] === "en" ? data.list_item2_en : data.list_item2_ar}
                                                    </li><br />
                                                    <li>
                                                        {i18next.languages[0] === "en" ? data.list_item3_en : data.list_item3_ar}
                                                    </li><br />
                                                    <li>
                                                        {i18next.languages[0] === "en" ? data.list_item4_en : data.list_item4_ar}
                                                    </li><br />
                                                    <li>
                                                        {i18next.languages[0] === "en" ? data.list_item5_en : data.list_item5_ar}
                                                    </li><br />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="new-tech">
                                        <div className="container">
                                            <div className="row d-md-flex justify-content-center">
                                                <div className="col-12 col-md-8 mb-5">
                                                    <p className="transcript">
                                                        {i18next.languages[0] === "en" ? data.paragraph2_en : data.paragraph2_ar}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gem-bg">
                                        <div className="col-md container text-center">
                                            <img className="standard-jewelry-report-image" src={url + data.report_image2[0].url} alt={data.report_image2[0].name} />
                                        </div>
                                        <div className="col-md-12 text-center ">
                                            <img src="https://i.ibb.co/7Wrcdhp/Diamonds-Gold.png" width="100px" alt="diamond gold" />
                                        </div>
                                    </div>
                                    <div className="service-video">

                                        <div className="player-wrapper-service container">
                                            <ReactPlayer
                                                url={url + data.video[0].url}
                                                className='react-player'
                                                playing
                                                controls={true}
                                                loop
                                                muted
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        )
    }
}
export default LargeJewelryReport;
