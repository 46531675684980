import React from 'react'
import axios from "axios"
import Loading from './Loading';

export default class Social extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/social-medias")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const data = this.state.data
        if (data.length === 0) {
            return (
                <Loading />
            )
        }
        return (
            <>
                {data.map(function (social) {
                    const icons = social.icon
                    return (
                        <li key={social.id} className="list-inline-item">
                            <a href={social.link} target="_blank" rel="noopener noreferrer">
                                <i className={icons} style={{ fontSize: 24 }}></i>
                            </a>
                        </li>
                    )
                })}
            </>
        )
    }
}
