import React from 'react';
import MapSection from './components/map/Map';
import Retailer from './components/retailer';
import RetailerSearch from './components/retailerSearch';
import RetailerInfo from './components/retailerInfo';
import { Translation } from "react-i18next";
import Helmet from "react-helmet";
import axios from "axios"
import Loading from './components/Loading';


class RetailLookup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            retailer: {
                name_en: '',
                name_ar: '',
                description_en: '',
                description_ar: '',
                location: {
                    address: 'Saudi Arabia',
                    lat: 24.7361768,
                    lng: 46.6641042
                },
                image: {
                    url: "https://admin.mtgl.com.sa",
                },
                phone: '',
                website: '',
                email: ''
            }, filteredRetailers: ''

        };
    }
    componentDidMount() {
        axios.get("https://admin.mtgl.com.sa/retailer-informations")
            .then(({ data }) => this.setState({ data }));
    }
    render() {
        const { retailer } = this.state;
        console.log(retailer)
        if (retailer.length === 0) {
            return (
                <Loading />
            )
        }
        return (
            <Translation>
                {(t) => (
                    <div>
                        <Helmet>
                            <title>{t("header_retailLookup")}</title>
                            <meta name="description" content={t("retailLookup_p1")} />
                        </Helmet>
                        <div className="container-fluid mt-5">
                            <div className="container retail-lookup">

                                <div className="row mt-5">

                                    <div className="col-12 col-md-6">
                                        <h1>{t("retailLookup")}</h1>
                                        <p>{t("retailLookup_p1")}</p>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <p className="text-right retailLookup_p2"> {t("retailLookup_p2")}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3 mt-3">
                                            <RetailerSearch handleSearch={this.searchBar} />
                                        </div>


                                        <Retailer data={this.state.filteredRetailers ? this.state.filteredRetailers : this.state.data} onClickLi={this.onClickLi} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 p-0">
                                    <MapSection location={retailer.location} zoomLevel={17} />
                                </div>
                            </div>
                            <div className="container">
                                <RetailerInfo retailer={retailer} />
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        )

    }
    searchBar = (event) => {

        let filteredRetailers = this.state.data.filter(
            (retailer) => {
                return retailer.name_en.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1,
                    retailer.name_ar.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
            }
        );
        this.setState({ filteredRetailers });
    }
    onClickLi = (index) => {
        /* get index of the clicked li */
        /* get element out of array state retailers */
        /* copy element into state retailer */
        let data = [...this.state.data];
        let retailer = { ...data[index] };
        this.setState({ retailer: retailer });
    }
}

export default RetailLookup;
